import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory } from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'
import { captureException } from '@sentry/react'
import clsx from 'clsx'
import type { FormikErrors, FormikHelpers } from 'formik'
import { Form, Formik } from 'formik'

import routes from 'src/routes'
import { TestIds } from 'src/testIds'
import { Region, SupportedSoftware } from 'src/types'
import { scrollTo } from 'src/utils/form.utils'
import useContracts from 'src/hooks/useContracts'
import { useSessionStorageState } from 'src/hooks/useForm'
import useRelease from 'src/hooks/useRelease'
import api from 'src/services/api'
import PanelSummaryContent from 'src/components/PanelSummaryContent/PanelSummaryContent'

import { getAdditionalAttributeIds } from '../../utils/additionalAttributes'
import {
  buildQualificationMatrices,
  getQualificationIds,
  getQualificationIdsForQualification,
  getQualifications,
  isQualificationSelected,
  QualificationMatrix,
} from '../../utils/qualifications'

import PanelBankDetail, {
  initialValues as initialValuesBankDetail,
  PanelBankDetailsType,
  validationSchema as validationSchemaBankDetail,
} from './PanelBankDetail/PanelBankDetail'
import PanelCheckData, {
  initialValues as initialValuesCheckData,
  PanelCheckDataType,
  validationSchema as validationSchemaCheckData,
} from './PanelCheckData/PanelCheckData'
import PanelOperatingFacility, {
  initialValues as initialValuesOperatingFacility,
  PanelOperatingFacilityType,
  validationSchema as validationSchemaOperatingFacility,
} from './PanelOperatingFacility/PanelOperatingFacility'
import PanelParticipationReq, {
  initialValues as initialValuesParticipationReq,
  PanelParticipationReqType,
  validationSchema as validationSchemaParticipationReq,
} from './PanelParticipationReq/PanelParticipationReq'
import PanelPersonalData, {
  initialValues as initialValuesPersonalData,
  PanelPersonalDataType,
  validationSchema as validationSchemaPersonalData,
} from './PanelPersonalData/PanelPersonalData'

import useStyles from './ContractForm.styles'

const ComponentTestIds = TestIds.components

const defaultInitialValues = {
  ...initialValuesPersonalData,
  ...initialValuesOperatingFacility,
  ...initialValuesParticipationReq,
  ...initialValuesBankDetail,
  ...initialValuesCheckData,
}

//! Note: The order of the validation schemas must be in the same order as their related panels.
const validationSchemas = (region: Region) => [
  validationSchemaPersonalData,
  validationSchemaOperatingFacility,
  validationSchemaParticipationReq(
    region.additionalAttributes,
    region.qualifications,
    region.verahWeeklyWorkTime,
    region.contractApplicationRequiresEmployerAuthorisation,
  ),
  validationSchemaBankDetail,
  validationSchemaCheckData(region.contractApplicationSurveyOptions),
]

export interface ContractFormType
  extends PanelPersonalDataType,
    PanelOperatingFacilityType,
    PanelParticipationReqType,
    PanelBankDetailsType,
    PanelCheckDataType {}

export interface ContractFormProps {
  className?: string
  currentRegion: Region | undefined
}

export const SESSION_STORAGE_KEY = 'contractForm'

export const ContractForm: FC<ContractFormProps> = ({
  className,
  currentRegion,
  ...props
}) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const [expandedPanel, setExpandedPanel] = useState(1)
  const [completedPanel, setCompletedPanel] = useState(0)

  const [supportedSoftwares, setSupportedSoftwares] = useState<
    SupportedSoftware[]
  >([])
  const {
    actions: contractActions,
    getters: contractGetters,
    state: contractState,
  } = useContracts()
  const { state: releaseState } = useRelease()
  const confirm = useConfirm()
  const history = useHistory()
  const releaseId = releaseState.release?.id || 0
  const selectedContractsObjects =
    contractGetters.getSubSetOfSelectedAndFilteredContracts()
  const selectedContractIds = selectedContractsObjects.map(
    (selectedContract) => selectedContract.id,
  )
  const matrices = useMemo<QualificationMatrix[]>(() => {
    return currentRegion
      ? buildQualificationMatrices(currentRegion, selectedContractsObjects)
      : []
  }, [currentRegion, selectedContractsObjects])

  const additionalAttributes = currentRegion?.additionalAttributes ?? []
  const isLastPanel = currentRegion
    ? validationSchemas(currentRegion).length === expandedPanel
    : false
  const redirectPath = (applicationId: number, employmentType: string) =>
    generatePath(routes.root.routes!.contracts.routes!.formSuccess.path, {
      applicationId,
      employmentType,
      regionSlug: contractState.contractList.currentRegion,
    })
  let initialValues = defaultInitialValues as ContractFormType

  const allQualifications = getQualifications(matrices)

  const { sessionStorageState, handleUpdateForm } = useSessionStorageState({
    additionalAttributeIds: getAdditionalAttributeIds(additionalAttributes),
    qualificationIds: getQualificationIds(allQualifications),
    storageKey: SESSION_STORAGE_KEY,
    values: initialValues,
  })

  // Some fields are required depending on the selection of another field.
  // We solve this by setting the initial values to null or '' (null = not required, '' = required).
  // Therefore, we need to set the initial values accordingly AFTER loading values from local storage.
  const initialValuesState: ContractFormType = {
    ...(sessionStorageState as ContractFormType),
    kimAddresses: allQualifications.map((qualification) =>
      isQualificationSelected(
        qualification,
        sessionStorageState.qualificationIds,
      ) && qualification.isKimEmailRequired
        ? ''
        : null,
    ),
    qualificationAnswers: allQualifications.map((qualification) =>
      isQualificationSelected(
        qualification,
        sessionStorageState.qualificationIds,
      ) && qualification.optionsList.length > 0
        ? ''
        : null,
    ),
    qualificationCustomAnswers: allQualifications.map(() => null), // all not required
  }

  const scrollToAccordion = (panelNo: number) =>
    scrollTo(ComponentTestIds.contractForm.panel + `_${panelNo}`)

  // Change expanded panel and update the used validation schema accordingly.
  const handlePanelChange =
    (panelNo: number) => (event: ChangeEvent<{}>, expanded: boolean) => {
      // Only expand previous panels (only edit complete panels).
      if (expanded && panelNo < expandedPanel) {
        setExpandedPanel(panelNo)
        scrollToAccordion(panelNo)
      }
    }

  // Scrolls to the first input with an error.
  const scrollToError = (errors: FormikErrors<ContractFormType>) => {
    const keys = Object.keys(errors)
    const selector = `[name="${keys[0]}"]`
    const errorElement = document.querySelector(selector)

    errorElement?.scrollIntoView()
  }

  // set initial validation schema
  const validationSchema = currentRegion
    ? validationSchemas(currentRegion)[expandedPanel - 1]
    : []
  useEffect(() => {
    let tmpCompletedPanel =
      expandedPanel - completedPanel > 1 ? completedPanel + 1 : completedPanel
    setCompletedPanel(tmpCompletedPanel)
  }, [expandedPanel, completedPanel])

  // Handles advancing through the form when the submit button of each panel was pressed.
  const onSubmit = async (
    values: ContractFormType,
    formikHelpers: FormikHelpers<ContractFormType>,
  ) => {
    if (isLastPanel) {
      const certificateFiles = [
        ...values.certificates,
        ...values.dmpCertificates,
      ]
      certificateFiles.forEach((element, index) => {
        if (element) {
          // @ts-ignore
          values[`certificates_${index}`] = element
        }
      })

      const cleanValues =
        currentRegion &&
        validationSchemas(currentRegion).reduce((acc, schema) => {
          return { ...acc, ...schema.cast(values) }
        }, {})
      values = { ...values, ...cleanValues }

      const { employerData, ...valuesWithoutEmployerData } = {
        ...values,
        ...cleanValues,
      }

      const isEmployerAuthorisationRequired =
        currentRegion?.contractApplicationRequiresEmployerAuthorisation

      const {
        qualificationIds,
        kimAddresses,
        qualificationAnswers,
        qualificationCustomAnswers,
        ...postValues
      } =
        isEmployerAuthorisationRequired && employerData
          ? // if the employer authorisation is required,
            // we need to merge the employer data with the rest of the values
            { ...valuesWithoutEmployerData, ...employerData }
          : values

      // ---- combine selected qualification ids with kim addresses and answers ---
      const qualificationPostValues = allQualifications.flatMap((q, index) => {
        const selectedQualificationIds = getQualificationIdsForQualification(
          q,
        ).filter((qid) => qualificationIds.includes(qid.toString()))
        const kimAddress = kimAddresses[index]

        return selectedQualificationIds.map((qualificationId) => {
          return {
            id: qualificationId,
            kimEmailAddress: kimAddress,
            selectedAnswerValue:
              qualificationCustomAnswers[index] || // prefer custom answer if present
              qualificationAnswers[index],
          }
        })
      })

      await api
        .postContractParticipationForm({
          regionalContractIds: selectedContractIds,
          releaseId,
          values: {
            ...postValues,
            qualifications: JSON.stringify(qualificationPostValues),
          },
        })
        .then(({ data }) => {
          contractActions.setSelectedContracts([])
          contractActions.setSelectedFilters([])
          history.push(
            redirectPath(data.applicationId, postValues.employmentType),
          )
        })
        .catch((error) => {
          captureException(error)
          confirm({
            cancellationButtonProps: { style: { display: 'none' } },
            content: (
              <Alert severity="error">
                <AlertTitle>{t('General.somethingWentWrong')}</AlertTitle>
              </Alert>
            ),
            title: t('General.error'),
          })
        })
    } else {
      formikHelpers.setTouched({})
      const nextExpandedPanel = expandedPanel + 1
      if (nextExpandedPanel - completedPanel === 2) {
        setExpandedPanel(nextExpandedPanel)
        scrollToAccordion(nextExpandedPanel)
      } else {
        setExpandedPanel(completedPanel + 1)
        scrollToAccordion(completedPanel + 1)
      }
    }
  }

  // get supportedSoftwares on mount.
  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: softwares } = await api.getSupportedSoftwares()

        setSupportedSoftwares(softwares)
      } catch (error) {
        setSupportedSoftwares([])
      }
    }

    fetchData()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Formik
      initialValues={initialValuesState}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {(formik) => {
        return (
          <Form
            className={clsx(classes.root, className)}
            data-test-id={ComponentTestIds.contractForm.wrapper}
            noValidate
            onBlur={() => handleUpdateForm(formik.values)}
            onSubmit={(event) => {
              if (!formik.isValid) scrollToError(formik.errors)
              formik.handleSubmit(event)
            }}
            {...props}
          >
            <Accordion
              data-test-id={ComponentTestIds.contractForm.panel}
              id={ComponentTestIds.contractForm.panel + '_1'}
              expanded={expandedPanel === 1}
              onChange={handlePanelChange(1)}
              TransitionProps={{
                // we need this to prevent miscalculated scroll position.
                timeout: 0,
              }}
              classes={{
                root: clsx(
                  completedPanel >= 1 && classes.accordionRootCompleted,
                ),
              }}
              elevation={10}
            >
              <AccordionSummary>
                <PanelSummaryContent
                  expandedPanel={expandedPanel}
                  completed={completedPanel >= 1}
                  panelNo={1}
                  title={t('ContractForm.personalData')}
                />
              </AccordionSummary>

              <AccordionDetails>
                <PanelPersonalData formik={formik} region={currentRegion} />
              </AccordionDetails>
            </Accordion>

            <Accordion
              data-test-id={ComponentTestIds.contractForm.panel}
              id={ComponentTestIds.contractForm.panel + '_2'}
              expanded={expandedPanel === 2}
              onChange={handlePanelChange(2)}
              TransitionProps={{
                timeout: 0,
              }}
              classes={{
                root: clsx(
                  completedPanel >= 2 && classes.accordionRootCompleted,
                ),
              }}
              elevation={10}
            >
              <AccordionSummary>
                <PanelSummaryContent
                  expandedPanel={expandedPanel}
                  completed={completedPanel >= 2}
                  panelNo={2}
                  title={t('ContractForm.bsData')}
                />
              </AccordionSummary>

              <AccordionDetails>
                <PanelOperatingFacility formik={formik} />
              </AccordionDetails>
            </Accordion>

            <Accordion
              data-test-id={ComponentTestIds.contractForm.panel}
              id={ComponentTestIds.contractForm.panel + '_3'}
              expanded={expandedPanel === 3}
              onChange={handlePanelChange(3)}
              TransitionProps={{
                timeout: 0,
              }}
              classes={{
                root: clsx(
                  completedPanel >= 3 && classes.accordionRootCompleted,
                ),
              }}
              elevation={10}
            >
              <AccordionSummary>
                <PanelSummaryContent
                  expandedPanel={expandedPanel}
                  completed={completedPanel >= 3}
                  panelNo={3}
                  title={t('ContractForm.participationRequirements')}
                />
              </AccordionSummary>

              <AccordionDetails>
                <PanelParticipationReq
                  formik={formik}
                  region={currentRegion}
                  supportedSoftwares={supportedSoftwares}
                  matrices={matrices}
                  additionalAttributes={additionalAttributes}
                />
              </AccordionDetails>
            </Accordion>

            <Accordion
              data-test-id={ComponentTestIds.contractForm.panel}
              id={ComponentTestIds.contractForm.panel + '_4'}
              expanded={expandedPanel === 4}
              onChange={handlePanelChange(4)}
              TransitionProps={{
                timeout: 0,
              }}
              classes={{
                root: clsx(
                  completedPanel >= 4 && classes.accordionRootCompleted,
                ),
              }}
              elevation={10}
            >
              <AccordionSummary>
                <PanelSummaryContent
                  expandedPanel={expandedPanel}
                  completed={completedPanel >= 4}
                  panelNo={4}
                  title={t('ContractForm.bankData')}
                />
              </AccordionSummary>

              <AccordionDetails>
                <PanelBankDetail formik={formik} />
              </AccordionDetails>
            </Accordion>

            <Accordion
              data-test-id={ComponentTestIds.contractForm.panel}
              id={ComponentTestIds.contractForm.panel + '_5'}
              expanded={expandedPanel === 5}
              onChange={handlePanelChange(5)}
              TransitionProps={{
                timeout: 0,
              }}
              classes={{
                root: clsx(
                  completedPanel >= 5 && classes.accordionRootCompleted,
                ),
              }}
              elevation={10}
            >
              <AccordionSummary>
                <PanelSummaryContent
                  expandedPanel={expandedPanel}
                  completed={completedPanel >= 5}
                  panelNo={5}
                  title={t('ContractForm.checkData')}
                />
              </AccordionSummary>

              <AccordionDetails>
                <PanelCheckData
                  regionalAssociationLabel={
                    currentRegion?.regionalAssociationLabel
                  }
                  regionalAssociationLabelCheckout={
                    currentRegion?.regionalAssociationLabelCheckoutHzv
                  }
                  formik={formik}
                  selectedContracts={selectedContractsObjects}
                  surveyOptions={
                    currentRegion?.contractApplicationSurveyOptions
                  }
                />
              </AccordionDetails>
            </Accordion>
          </Form>
        )
      }}
    </Formik>
  )
}

export default ContractForm
