import type { AxiosResponse } from 'axios'

import { UserDatatype } from '../../contexts/UserDataContext'

import axi from './axios'

export interface SSOCallbackParams {
  state: string
  code: string
}

export interface SSOCallbackResponse {
  user: UserDatatype
  regionSlug: string
  applicationType: string
}

export const submitSSOCallback = async (
  requestProps: SSOCallbackParams,
): Promise<AxiosResponse<SSOCallbackResponse>> => {
  const requestUrl = `sso/user/`

  return axi.post(requestUrl, requestProps, { withCredentials: true })
}
