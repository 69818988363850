import { useContext } from 'react'

import UserDataContext from 'src/contexts/UserDataContext'

/**
 * @description Provides the UserDataContext.
 */
export const useUserData = () => {
  const context = useContext(UserDataContext)
  if (context === undefined) {
    throw new Error('useUserData must be used within a UserDataProvider')
  }
  return context
}

export default useUserData
