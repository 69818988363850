import { useCallback, useState } from 'react'
import { isEqual } from 'lodash'

import {
  ContractFormType,
  SESSION_STORAGE_KEY as ContractFormKey,
} from '../components/ContractForm/ContractForm'
import {
  MembershipFormType,
  SESSION_STORAGE_KEY as MembershipFormKey,
} from '../components/MembershipForm/MembershipForm'
import { multipleIds } from '../utils/cacheValidation'

// we excluded the first form step as it leads to issues
interface PartialMembershipFormType
  extends Omit<MembershipFormType, 'PanelTypeOfMembershipType'> {}

type SessionStorageStateType = {
  storageKey: string
  values: PartialMembershipFormType | ContractFormType
  additionalAttributeIds?: Array<number>
  qualificationIds?: Array<number>
}

interface useSessionStorageStateInterface {
  sessionStorageState: PartialMembershipFormType | ContractFormType
  handleUpdateForm: (x: PartialMembershipFormType | ContractFormType) => void
}

const commonFields: Record<string, string> = {
  accountHolder: 'accountHolder',
  bankName: 'bankName',
  bic: 'bic',
  iban: 'iban',
  title: 'title',
}

const contractFormFields: Record<string, string> = {
  additionalAttributeIds: 'additionalAttributeIds',
  authorisedByTheKvAdmissionsCommittee: 'authorisedByTheKvAdmissionsCommittee',
  // https://gitlab.ambient-innovation.com/havg-rz/haso/-/issues/142#note_584965
  bsActiveSince1: 'MainPraxisActiveSince',
  bsActiveSince2: 'bsActiveSince2',
  bsActiveSince3: 'bsActiveSince3',
  bsCity1: 'MainPraxisCity',
  bsCity2: 'bsCity2',
  bsCity3: 'bsCity3',
  bsEmail1: 'MainPraxisEmail',
  bsEmail2: 'bsEmail2',
  bsEmail3: 'bsEmail3',
  bsFax1: 'MainPraxisFax',
  bsFax2: 'bsFax2',
  bsFax3: 'bsFax3',
  bsId1: 'MainPraxisBsId',
  bsId2: 'bsId2',
  bsId3: 'bsId3',
  bsNameOfFacility1: 'MainPraxisName',
  bsNameOfFacility2: 'bsNameOfFacility2',
  bsNameOfFacility3: 'bsNameOfFacility3',
  bsPhoneMobile1: 'MainPraxisPhoneMobile',
  bsPhoneMobile2: 'bsPhoneMobile2',
  bsPhoneMobile3: 'bsPhoneMobile3',
  bsPhoneOffice1: 'MainPraxisPhoneOffice',
  bsPhoneOffice2: 'bsPhoneOffice2',
  bsPhoneOffice3: 'bsPhoneOffice3',
  bsPostalCode1: 'MainPraxisPostalCode',
  bsPostalCode2: 'bsPostalCode2',
  bsPostalCode3: 'bsPostalCode3',
  bsStreet1: 'MainPraxisStreet',
  bsStreet2: 'bsStreet2',
  bsStreet3: 'bsStreet3',
  bsStreetNo1: 'MainPraxisStreetNo',
  bsStreetNo2: 'bsStreetNo2',
  bsStreetNo3: 'bsStreetNo3',
  bsType1: 'MainPraxisType',
  bsType2: 'bsType2',
  bsType3: 'bsType3',
  certificates: 'certificates',
  dateOfBirth: 'dateOfBirth',
  dmpAsthma: 'dmpAsthma',
  dmpCopd: 'dmpCopd',
  dmpDiabetes: 'dmpDiabetes',
  dmpKhk: 'dmpKhk',
  efnId: 'efnId',
  employerConsent: 'employerConsent',
  employerName: 'employerName',
  employmentType: 'employmentType',
  firstName: 'firstName',
  haevgId: 'haevgId',
  hzvNews: 'hzvNews',
  isMember: 'isMember',
  lanrId: 'lanrId',
  lastName: 'lastName',
  otherSurveyOption: 'otherSurveyOption',
  qualificationIds: 'qualificationIds',
  softwareId: 'softwareId',
  survey: 'survey',
  toggleOtherSurveyOption: 'toggleOtherSurveyOption',
  verahCoworkers: 'verahCoworkers',
  ...commonFields,
}

const membershipFields: Record<string, string> = {
  bsActiveSince: 'MainPraxisActiveSince',
  bsCity: 'MainPraxisCity',
  bsEmail: 'MainPraxisEmail',
  bsFax: 'MainPraxisFax',
  bsId: 'MainPraxisBsId',
  bsName: 'MainPraxisName',
  bsPhoneMobile: 'MainPraxisPhoneMobile',
  bsPhoneOffice: 'MainPraxisPhoneOffice',
  bsPostalCode: 'MainPraxisPostalCode',
  bsStreet: 'MainPraxisStreet',
  bsStreetNo: 'MainPraxisStreetNo',
  bsType: 'MainPraxisType',
  dateOfBirth: 'dateOfBirth',
  debitConsent: 'debitConsent',
  efnId: 'efnId',
  email: 'email',
  firstName: 'firstName',
  hzvNews: 'hzvNews',
  isPtqzInterested: 'isPtqzInterested',
  isPtqzModerator: 'isPtqzModerator',
  lanrId: 'lanrId',
  lastName: 'lastName',
  medicalSpecialisation: 'medicalSpecialisation',
  otherSurveyOption: 'otherSurveyOption',
  personalCity: 'personalCity',
  personalFax: 'personalFax',
  personalPhone: 'personalPhone',
  personalPhoneMobile: 'personalPhoneMobile',
  personalPostalCode: 'personalPostalCode',
  personalStreet: 'personalStreet',
  personalStreetNo: 'personalStreetNo',
  privacyPolicyConsent: 'privacyPolicyConsent',
  selectedBonus: 'selectedBonus',
  statuteConsent: 'statuteConsent',
  survey: 'survey',
  toggleOtherSurveyOption: 'toggleOtherSurveyOption',
  ...commonFields,
}

export const useSessionStorageState = ({
  storageKey,
  values,
  qualificationIds,
  additionalAttributeIds,
}: SessionStorageStateType): useSessionStorageStateInterface => {
  const initialValues: Record<string, any> = {}
  const cleanStorageIds = (
    ids: Array<number>,
    cacheKey: string,
    formKey: string,
  ) => {
    // we are comparing the cached ids with the ids coming from backend and deleting those from cache that do not
    // exist anymore. This way we avoid that we keep invalid ids in the cache that are sent to backend what results in
    // invalid applications. See: https://gitlab.ambient-innovation.com/havg-rz/haso/-/issues/162
    const storedIds = JSON.parse(sessionStorage.getItem(cacheKey) as string)
    const cleanedIds = multipleIds(ids, storedIds)
    sessionStorage.setItem(cacheKey, JSON.stringify(cleanedIds, null, 2))
    initialValues[formKey] = cleanedIds
  }

  if (storageKey === ContractFormKey) {
    Object.entries(contractFormFields).forEach(([formKey, cacheKey]) => {
      if (sessionStorage.getItem(cacheKey)) {
        if (
          cacheKey === contractFormFields.qualificationIds &&
          qualificationIds
        ) {
          cleanStorageIds(qualificationIds, cacheKey, formKey)
        } else if (
          cacheKey === contractFormFields.additionalAttributeIds &&
          additionalAttributeIds
        ) {
          cleanStorageIds(additionalAttributeIds, cacheKey, formKey)
        } else {
          initialValues[formKey] = JSON.parse(
            sessionStorage.getItem(cacheKey) as string,
          )
        }
      }
    })
  }

  if (storageKey === MembershipFormKey) {
    Object.entries(membershipFields).forEach(([formKey, cacheKey]) => {
      if (sessionStorage.getItem(cacheKey)) {
        initialValues[formKey] = JSON.parse(
          sessionStorage.getItem(cacheKey) as string,
        )
      }
    })
  }

  const [sessionStorageState, setSessionStorageState] = useState<
    MembershipFormType | ContractFormType
  >({
    ...values,
    ...initialValues,
  })

  const handleUpdateForm = useCallback(
    (x) => {
      // we are checking here if the given values object is different from the initialValues. This is needed for the
      // case, the user just touches one form and switches to another. In this case an empty values object would be
      // saved in local storage and would prevent overriding values later on from the other form. The reason why it
      // would be prevented is that we only want to override the values once. See if conditions in lines 40 and 79.
      if (!isEqual(x, sessionStorageState)) {
        setSessionStorageState(x)
        Object.entries(x).forEach(([key, value]) => {
          if (Object.keys(contractFormFields).includes(key)) {
            sessionStorage.setItem(
              contractFormFields[key],
              JSON.stringify(value, null, 2),
            )
          }
          if (Object.keys(membershipFields).includes(key)) {
            sessionStorage.setItem(
              membershipFields[key],
              JSON.stringify(value, null, 2),
            )
          }
        })
      }
    },
    [sessionStorageState],
  )
  return { handleUpdateForm, sessionStorageState: sessionStorageState }
}
