import type { ElementType } from 'react'
import { Fragment, Suspense } from 'react'
import { generatePath, Redirect, Route, Switch } from 'react-router-dom'

import HeaderFooterFullWidthLayout from 'src/layouts/HeaderFooterFullWidthLayout/HeaderFooterFullWidthLayout'
import ContactView from 'src/views/ContactView/ContactView'
import ContractFormView from 'src/views/ContractFormView/ContractFormView'
import ContractListView from 'src/views/ContractListView/ContractListView'
import ContractsView from 'src/views/ContractsView/ContractsView'
import EmployerAuthorisationSuccessView from 'src/views/EmployerAuthorisationSuccessView/EmployerAuthorisationSuccessView'
import ErrorView from 'src/views/ErrorView/ErrorView'
import FormSuccessView from 'src/views/FormSuccessView/FormSuccessView'
import GtcView from 'src/views/GtcView/GtcView'
import HomeView from 'src/views/HomeView/HomeView'
import LegalNoticeView from 'src/views/LegalNoticeView/LegalNoticeView'
import MembershipFormView from 'src/views/MembershipFormView/MembershipFormView'
import MembershipView from 'src/views/MembershipView/MembershipView'
import PrivacyView from 'src/views/PrivacyView/PrivacyView'
import SSOView from 'src/views/SSOView/SSOView'
import LoadingScreen from 'src/components/LoadingScreen/LoadingScreen'
import MembershipGuard from 'src/components/MembershipGuard/MembershipGuard'
import PreviewGuard from 'src/components/PreviewGuard/PreviewGuard'
import RegionGuard from 'src/components/RegionGuard/RegionGuard'

const routes = {
  //! Note: Order matters.
  errorPage: {
    component: ErrorView,
    exact: true,
    path: '/error/:errorCode',
    layout: HeaderFooterFullWidthLayout,
  },

  root: {
    path: '/',
    guard: PreviewGuard,
    routes: {
      index: {
        get path() {
          return `${routes.root.path}`
        },
        component: HomeView,
        exact: true,
        layout: HeaderFooterFullWidthLayout,
      },

      employerAuthorisationSuccess: {
        component: EmployerAuthorisationSuccessView,
        exact: true,
        get path() {
          return `${routes.root.path}vetragsbestaetigung`
        },
        layout: HeaderFooterFullWidthLayout,
      },

      contracts: {
        layout: HeaderFooterFullWidthLayout,
        path: '/vertraege',
        routes: {
          index: {
            component: ContractsView,
            exact: true,
            get path() {
              return `${routes.root.routes!.contracts.path}`
            },
          },

          contractList: {
            component: ContractListView,
            exact: true,
            guard: RegionGuard,
            get path() {
              return `${routes.root.routes!.contracts.path}/:regionSlug`
            },
          },

          contractForm: {
            component: ContractFormView,
            exact: true,
            guard: RegionGuard,
            get path() {
              return `${
                routes.root.routes!.contracts.path
              }/:regionSlug/beantragen`
            },
          },

          formSuccess: {
            component: FormSuccessView,
            exact: true,
            guard: RegionGuard,
            get path() {
              return `${
                routes.root.routes!.contracts.path
              }/:regionSlug/anwendung/:applicationId/:employmentType/danke`
            },
          },

          fallback: {
            component: (): any => (
              <Redirect
                to={generatePath(routes.errorPage.path, {
                  errorCode: 404,
                })}
              />
            ),
            path: '',
          },
        },
      },

      membership: {
        layout: HeaderFooterFullWidthLayout,
        path: '/mitglied',
        guard: MembershipGuard,
        routes: {
          index: {
            component: MembershipView,
            exact: true,
            get path() {
              return `${routes.root.routes!.membership.path}`
            },
          },

          membershipForm: {
            component: MembershipFormView,
            exact: true,
            guard: RegionGuard,
            get path() {
              return `${routes.root.routes!.membership.path}/:regionSlug`
            },
          },

          formSuccess: {
            component: FormSuccessView,
            exact: true,
            guard: RegionGuard,
            get path() {
              return `${
                routes.root.routes!.membership.path
              }/:regionSlug/anwendung/:applicationId/danke`
            },
          },

          fallback: {
            component: (): any => <Redirect to={routes.errorPage.path} />,
            path: '',
          },
        },
      },

      contact: {
        component: ContactView,
        exact: true,
        layout: HeaderFooterFullWidthLayout,
        path: '/kontakt',
      },

      legalNotice: {
        component: LegalNoticeView,
        exact: true,
        layout: HeaderFooterFullWidthLayout,
        path: '/impressum',
      },

      privacy: {
        component: PrivacyView,
        exact: true,
        layout: HeaderFooterFullWidthLayout,
        path: '/datenschutz',
      },

      gtc: {
        component: GtcView,
        exact: true,
        layout: HeaderFooterFullWidthLayout,
        path: '/agb',
      },

      sso: {
        component: SSOView,
        exact: true,
        layout: HeaderFooterFullWidthLayout,
        path: '/sso',
      },

      fallback: {
        component: (): any => (
          <Redirect
            to={generatePath(routes.errorPage.path, {
              errorCode: 404,
            })}
          />
        ),
        path: '',
      },
    },
  },
}

type Routes = {
  [key: string]: {
    component?: ElementType
    exact?: boolean
    guard?: any
    layout?: any
    params?: string[]
    path: string
    routes?: Routes
  }
}

export const renderRoutes = (routes: Routes = {}): JSX.Element => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {Object.entries(routes).map(([routeName, route]) => {
        const Guard = route.guard || Fragment
        const Layout = route.layout || Fragment
        const Component = route.component

        return (
          <Route
            exact={route.exact}
            key={routeName}
            path={route.path}
            render={(props: any) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <>{Component && <Component {...props} />}</>
                  )}
                </Layout>
              </Guard>
            )}
          />
        )
      })}
    </Switch>
  </Suspense>
)

export default routes
