import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@material-ui/core'

import { SHOW_SSO_BANNER, ssoLoginUrl } from 'src/constants'
import { TestIds } from 'src/testIds'
import useUserData from 'src/hooks/useUserData'
import Badge from 'src/components/Badge/Badge'
import Button from 'src/components/Button/Button'
import ContactStripe from 'src/components/ContactStripe/ContactStripe'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import Page from 'src/components/Page/Page'
import RegionSelect from 'src/components/RegionSelect/RegionSelect'

import useStyles from './MembershipView.styles'

const MembershipTestIds = TestIds.views.membership

const MembershipView: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { isInitialized } = useUserData()
  return (
    <Page
      className={classes.root}
      data-test-id={MembershipTestIds.pageWrapper}
      title={t('Views.Membership.title')}
    >
      {/* Hero Section */}
      <ContentStripe
        className={classes.hero}
        data-test-id={MembershipTestIds.heroTeaser}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={12}>
            <Typography
              className={classes.headline}
              variant="h1"
              color="primary"
              align="center"
            >
              {t('Views.Membership.header')}
            </Typography>
            <Typography
              className={classes.subtitle}
              variant="subtitle1"
              align="center"
            >
              {t('Views.Membership.subheader')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={6}>
            <RegionSelect
              buttonText={t('Views.Membership.regionButton')}
              context="membership"
            />
            <Typography className={classes.infoText} align="center">
              {t('Views.Contracts.regionText')}
            </Typography>
          </Grid>
        </Grid>
      </ContentStripe>

      {/* Login CTA */}
      {SHOW_SSO_BANNER && !isInitialized && (
        <ContentStripe className={classes.ctaContainer}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={9}>
              <Box className={classes.ctaBox}>
                <Typography variant="h3">
                  {t('Views.Contracts.loginText')}
                </Typography>
                <Button variant="contained" href={ssoLoginUrl('membership')}>
                  {t('Views.Contracts.loginButton')}
                </Button>
              </Box>
            </Grid>
          </Grid>
        </ContentStripe>
      )}

      {/* How it works */}
      <ContentStripe
        className={classes.howItWorksContainer}
        data-test-id={MembershipTestIds.itWorksElement}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          spacing={5}
        >
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              {t('Views.Membership.Content.Works.header')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid
              className={classes.badgeAndTextContainer}
              container
              justifyContent="center"
              spacing={5}
            >
              <Grid item xs={10} sm={5} md={3}>
                <Badge
                  className={classes.numberBadge}
                  badgeContent={1}
                  size="large"
                  color="primary"
                />
                <Typography align="center">
                  {t('Views.Membership.Content.Works.firstText')}
                </Typography>
              </Grid>
              <Grid item xs={10} sm={5} md={3}>
                <Badge
                  className={classes.numberBadge}
                  badgeContent={2}
                  size="large"
                  color="primary"
                />
                <Typography align="center">
                  {t('Views.Membership.Content.Works.secondText')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid className={classes.infoButtonContainer} item xs={12}>
            <Button
              variant="contained"
              color="primary"
              href="https://www.haev.de/mitgliedschaft/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Views.Membership.Content.Works.button')}
            </Button>
          </Grid>
        </Grid>
      </ContentStripe>
      {/* Contact Section */}
      <ContactStripe />
    </Page>
  )
}

export default MembershipView
