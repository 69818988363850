import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, Typography } from '@material-ui/core'

import { TestIds } from 'src/testIds'
import Page from 'src/components/Page/Page'

import useUserData from '../../hooks/useUserData'

const SSOTestIds = TestIds.views.sso

const SSOView: FC = () => {
  const { t } = useTranslation()
  const { isLoading, error } = useUserData()

  return (
    <Page data-test-id={SSOTestIds.pageWrapper} title={t('Views.SSO.title')}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="50vh"
      >
        {isLoading ? (
          <>
            <CircularProgress size={60} />
            <Typography variant="h6" style={{ marginTop: 16 }}>
              {t('Views.SSO.loading')}
            </Typography>
          </>
        ) : error ? (
          <>
            <Typography variant="h6" color="error" gutterBottom>
              {error}
            </Typography>
          </>
        ) : (
          <Typography variant="h6">{t('Views.SSO.redirecting')}</Typography>
        )}
      </Box>
    </Page>
  )
}

export default SSOView
