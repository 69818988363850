import type { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@material-ui/core'

import { SHOW_SSO_BANNER, ssoLoginUrl } from 'src/constants'
import { TestIds } from 'src/testIds'
import useUserData from 'src/hooks/useUserData'
import Badge from 'src/components/Badge/Badge'
import Button from 'src/components/Button/Button'
import ContactStripe from 'src/components/ContactStripe/ContactStripe'
import ContentStripe from 'src/components/ContentStripe/ContentStripe'
import GradientBorderWrapper from 'src/components/GradientBorderWrapper/GradientBorderWrapper'
import Page from 'src/components/Page/Page'
import RegionSelect from 'src/components/RegionSelect/RegionSelect'

import useStyles from './ContractsView.styles'

const ContractTestIds = TestIds.views.contract

const ContractsView: FC = () => {
  const { t } = useTranslation()
  const classes = useStyles()
  const { isInitialized } = useUserData()

  return (
    <Page
      className={classes.root}
      data-test-id={ContractTestIds.pageWrapper}
      title={t('Views.Contracts.title')}
    >
      {/* Hero Section */}
      <ContentStripe
        className={classes.hero}
        data-test-id={ContractTestIds.heroTeaser}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={12}>
            <Typography
              className={classes.headline}
              variant="h1"
              color="primary"
              align="center"
            >
              {t('Views.Contracts.header')}
            </Typography>
            <Typography
              className={classes.subtitle}
              variant="subtitle1"
              align="center"
            >
              {t('Views.Contracts.subheader')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={8} lg={6}>
            <RegionSelect
              buttonText={t('Views.Contracts.regionButton')}
              context="contracts"
              isAccent
            />
            <Typography className={classes.infoText} align="center">
              {t('Views.Contracts.regionText')}
            </Typography>
          </Grid>
        </Grid>
      </ContentStripe>

      {/* Login CTA */}
      {SHOW_SSO_BANNER && !isInitialized && (
        <ContentStripe className={classes.ctaContainer}>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} md={9}>
              <GradientBorderWrapper className={classes.gradientWrapper}>
                <Box className={classes.ctaBox}>
                  <Typography variant="h3">
                    {t('Views.Contracts.loginText')}
                  </Typography>
                  <Button variant="contained" href={ssoLoginUrl('contracts')}>
                    {t('Views.Contracts.loginButton')}
                  </Button>
                </Box>
              </GradientBorderWrapper>
            </Grid>
          </Grid>
        </ContentStripe>
      )}

      {/* How it works */}
      <ContentStripe
        className={classes.howItWorksContainer}
        data-test-id={ContractTestIds.itWorksElement}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          alignContent="center"
          spacing={5}
        >
          <Grid item xs={12}>
            <Typography variant="h3" align="center">
              {t('Views.Contracts.Content.Works.header')}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Grid
              className={classes.badgeAndTextContainer}
              container
              justifyContent="center"
              spacing={5}
            >
              <Grid item xs={10} sm={4} md={3}>
                <Badge
                  className={classes.numberBadge}
                  badgeContent={1}
                  size="large"
                  color="accent"
                />
                <Typography align="center">
                  {t('Views.Contracts.Content.Works.firstText')}
                </Typography>
              </Grid>
              <Grid item xs={10} sm={4} md={3}>
                <Badge
                  className={classes.numberBadge}
                  badgeContent={2}
                  size="large"
                  color="accent"
                />
                <Typography align="center">
                  {t('Views.Contracts.Content.Works.secondText')}
                </Typography>
              </Grid>
              <Grid item xs={10} sm={4} md={3}>
                <Badge
                  className={classes.numberBadge}
                  badgeContent={3}
                  size="large"
                  color="accent"
                />
                <Typography align="center">
                  {t('Views.Contracts.Content.Works.thirdText')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid className={classes.infoButtonContainer} item xs={12}>
            <Button
              variant="contained"
              color="accent"
              href="https://hzv.de/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('Views.Contracts.Content.Works.button')}
            </Button>
          </Grid>
        </Grid>
      </ContentStripe>
      {/* Contact Section */}
      <ContactStripe variant="accent" />
    </Page>
  )
}

export default ContractsView
