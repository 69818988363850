import type { FC, ReactNode } from 'react'
import React, { createContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'

import { handleError } from 'src/utils/error.utils'

import { submitSSOCallback } from '../services/api/sso'

export interface UserDataProviderProps {
  children?: ReactNode
}

export type UserDatatype = {
  arzt: {
    titel: string
    vorname: string
    nachname: string
    geburtsdatum: string
    lanr: string
    haevgId: number
    lvId: number
    efn: string
    adresse: {
      strasse: string
      ort: string
      plz: string
      telefon: string
      mobil: string
      fax: string
      email: string
    } | null
  }
  hauptbetriebsstaette: {
    name: string
    art: number
    bsnr: string
    taetigSeit: string
    adresse: {
      strasse: string
      ort: string
      plz: string
      telefon: string
      mobil: string
      fax: string
      email: string
    } | null
  }
  nebenbetriebsstaetten: {
    name: string
    art: number
    bsnr: string
    taetigSeit: string
    adresse: {
      strasse: string
      ort: string
      plz: string
      telefon: string
      mobil: string
      fax: string
      email: string
    }
  }[]
  verahs: {
    vorname: string
    nachname: string
    geburtsname: string
    geburtsdatum: string
    taetigSeit: string
  }[]
  bankverbindung: {
    kontoinhaber: string
    institut: string
    iban: string
    bic: string
  } | null
  vertraege: string[]
}

const verahsMap: Record<string, string> = {
  geburtsdatum: 'dateOfBirth',
  geburtsname: 'birthName',
  nachname: 'lastName',
  taetigSeit: 'activeSince',
  vorname: 'firstName',
  weeklyHours: 'weeklyHours',
}

const bankkeysMap: Record<string, string> = {
  bic: 'bic',
  iban: 'iban',
  institut: 'bankName',
  kontoinhaber: 'accountHolder',
}

const praxisMap: Record<string, string> = {
  art: 'MainPraxisType',
  bsnr: 'MainPraxisBsId',
  name: 'MainPraxisName',
  taetigSeit: 'MainPraxisActiveSince',
}

const additionalPraxisMap = (index: number): Record<string, string> => ({
  art: 'bsType' + index,
  bsnr: 'bsId' + index,
  name: 'bsNameOfFacility' + index,
  taetigSeit: 'bsActiveSince' + index,
})

const praxisAddressMap: Record<string, string> = {
  email: 'MainPraxisEmail',
  fax: 'MainPraxisFax',
  mobil: 'MainPraxisPhoneMobile',
  ort: 'MainPraxisCity',
  plz: 'MainPraxisPostalCode',
  strasse: 'MainPraxisStreet',
  telefon: 'MainPraxisPhoneOffice',
}

const additionalPraxisAddressMap = (index: number): Record<string, string> => ({
  email: 'bsEmail' + index,
  fax: 'bsFax' + index,
  mobil: 'bsPhoneMobile' + index,
  ort: 'bsCity' + index,
  plz: 'bsPostalCode' + index,
  strasse: 'bsStreet' + index,
  telefon: 'bsPhoneOffice' + index,
})

const doctorMap: Record<string, string> = {
  efn: 'efnId',
  geburtsdatum: 'dateOfBirth',
  haevgId: 'haevgId',
  lanr: 'lanrId',
  nachname: 'lastName',
  titel: 'title',
  vorname: 'firstName',
}

const doctorPersonalAddressMap: Record<string, string> = {
  email: 'email',
  fax: 'personalFax',
  mobil: 'personalPhoneMobile',
  ort: 'personalCity',
  plz: 'personalPostalCode',
  strasse: 'personalStreet',
  telefon: 'personalPhone',
}

const mapObject = (
  obj: Record<string, any>,
  map: Record<string, string>,
): Record<string, string> => {
  const mapkeys = Object.keys(map)
  return Object.keys(obj).reduce((acc, key) => {
    if (mapkeys.includes(key)) {
      const mapKey = map[key]
      return { ...acc, [mapKey]: obj[key] }
    }
    return acc
  }, {})
}

/**
 * Save fetched user data to sessionstorage
 * @param userData
 */
const saveUserDataToCache = (userData: UserDatatype): void => {
  const mappedkeys: Record<string, any> = {
    ...mapObject(userData.arzt, doctorMap),
    ...(!!userData.arzt.adresse &&
      mapObject(userData.arzt.adresse, doctorPersonalAddressMap)),
    ...(!!userData.hauptbetriebsstaette &&
      mapObject(userData.hauptbetriebsstaette, praxisMap)),
    ...(!!userData.hauptbetriebsstaette &&
      !!userData.hauptbetriebsstaette.adresse &&
      mapObject(userData.hauptbetriebsstaette.adresse, praxisAddressMap)),
    // additional praxis addresses
    ...(!!userData.nebenbetriebsstaetten[0] &&
      mapObject(userData.nebenbetriebsstaetten[0], additionalPraxisMap(1))),
    ...(!!userData.nebenbetriebsstaetten[0] &&
      !!userData.nebenbetriebsstaetten[0].adresse &&
      mapObject(
        userData.nebenbetriebsstaetten[0].adresse,
        additionalPraxisAddressMap(1),
      )),
    ...(!!userData.nebenbetriebsstaetten[1] &&
      mapObject(userData.nebenbetriebsstaetten[1], additionalPraxisMap(2))),
    ...(!!userData.nebenbetriebsstaetten[1] &&
      !!userData.nebenbetriebsstaetten[1].adresse &&
      mapObject(
        userData.nebenbetriebsstaetten[1].adresse,
        additionalPraxisAddressMap(2),
      )),
    verahCoworkers: userData.verahs.map((verah) =>
      // workaround: weeklyHours should have a default value for the validation to works.
      mapObject({ ...verah, weeklyHours: '' }, verahsMap),
    ),
    ...(!!userData.bankverbindung &&
      mapObject(userData.bankverbindung, bankkeysMap)),
  }
  // save to sessiontorage to overwrite the current form cache
  Object.entries(mappedkeys).forEach(([key, value]) => {
    value = typeof value === 'number' ? value.toString() : value
    sessionStorage.setItem(key, JSON.stringify(value, null, 2))
  })
}

interface UserContextType {
  userData: UserDatatype | undefined
  isLoading: boolean
  error: string | null
  isAuthenticated: boolean
  hasMembershipStatus: boolean
  isInitialized: boolean
}

const UserDataContext = createContext<UserContextType>({
  error: null,
  hasMembershipStatus: false,
  isAuthenticated: false,
  isInitialized: false,
  isLoading: false,
  userData: undefined,
})

export const UserDataProvider: FC<UserDataProviderProps> = ({ children }) => {
  const { t } = useTranslation()
  const { search } = useLocation()
  const history = useHistory()
  const [userData, setUserData] = useState<UserDatatype | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [isInitialized, setIsInitialized] = useState<boolean>(false)
  const isAuthenticated = !!userData

  const query = React.useMemo(() => new URLSearchParams(search), [search])
  const state = query.get('state')
  const code = query.get('code')
  const query_error = query.get('error')

  const hasMembershipStatus = userData ? !!userData.arzt.haevgId : false

  const checkAuthentication = async () => {
    // If all SSO parameters are available, start the callback process
    if (state && code) {
      setIsLoading(true)
      setError(null)

      // Reload URL without SSO-Params
      const newUrl = window.location.pathname
      history.replace(newUrl)

      try {
        const { data: response } = await submitSSOCallback({
          code,
          state,
        })

        // Authentication successful
        if (response && response.user) {
          setUserData({ ...response.user })
          saveUserDataToCache({ ...response.user })
          setIsInitialized(true)

          // Redirect to contract region or home
          if (response.regionSlug) {
            history.push(`/${response.applicationType}/${response.regionSlug}`)
          }
        } else {
          setError(t('Views.SSO.errorAuthentication'))
          handleError(error)
        }
      } catch (err) {
        handleError(error)
        setError(t('Views.SSO.errorAuthentication'))
      } finally {
        setIsLoading(false)
      }
    }

    if (query_error) {
      setError(t('Views.SSO.errorAuthentication'))
      handleError(query_error)
    }
  }

  useEffect(() => {
    checkAuthentication()
  }, [search])

  return (
    <UserDataContext.Provider
      value={{
        error,
        hasMembershipStatus,
        isAuthenticated,
        isInitialized,
        isLoading,
        userData,
      }}
    >
      {children}
    </UserDataContext.Provider>
  )
}

export const UserDataConsumer = UserDataContext.Consumer

export default UserDataContext
